.App {
  text-align: center;
  flex: 1 0 auto;
}
.App-Center {
  transition: all;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  padding-bottom: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ads stuff */
.snigel-sidev {
  opacity: 1;
  pointer-events: none;
}

@media screen and (max-width: 1152px) {
  .snigel-sidev {
    opacity: 0;
    pointer-events: none;
    z-index: 0;
    display: none !important;
  }
}
