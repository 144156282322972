@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family:
    'Inter',
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.faq-content p { margin-bottom: 15px;margin-top:0;text-align: left; }
.faq-content ul { margin-bottom: 15px;display:flex;flex-direction: column; }
.faq-content ul li { display:flex;}
.faq-content ul li:before {
  content: '•';
  margin-right:8px;
}
.faq-content .row-title {
  text-align: left;
  font-weight: bold;
}

.faq-content .row-content {
  text-align: left;
}

.single-faq .faq-btn { transition: all 150ms ease-in-out;text-align: left; }
.single-faq .faq-content { transition: all 150ms ease-in-out;}
